<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">
            {{ $t('menu.companyConfig') }}
          </h1>

        </v-toolbar>
        <v-card>
          <v-toolbar flat>
            <v-tooltip color="primary" right>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title class="title"
                                 v-bind="attrs"
                                 v-on="on">
                  {{ $t('company_notifications.title') }}
                  <v-icon
                    color="primary"
                    dark
                    x-small
                  >
                    mdi-information
                  </v-icon>

                </v-toolbar-title>
              </template>
              <span>{{ $t('company_notifications.info_tooltip')}}</span>
            </v-tooltip>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-card outlined class="grey lighten-5 px-5">
              <v-row>
                <v-col
                  v-for="{id, name} in notificationsConfig"
                  :key="id"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="3"
                >
                  <v-switch
                    v-model="notificationsConfig.find((config) => config.id === id).value"
                    inset
                    :label="name"
                    color="success"
                    :data-test="`company-notifications__${id}`"
                  />
                </v-col>
              </v-row>
            </v-card>

            <div class="text-center pt-5 mt-5 mb-5">
              <v-btn
                :loading="isSendingSetCompanyConfig"
                depressed
                :disabled="isSendingSetCompanyConfig"
                data-test="company-notifications__save"
                color="primary"
                @click="save"
              >{{ $t('company_notifications.save_config') }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { useCompanyConfig } from "@/Company/Configuration/use/useCompanyConfig";
import {authUserService} from "@/services/auth-user";

import Alerts from '@/mixins/Alerts';
import {getHeader, getReceptionistsUrl} from "@/config/config";

export default {
  mixins: [Alerts],
  setup() {
    const {
      isNetworkError,
      notificationsConfig,
      isSendingSetCompanyConfig,
      getCompanyConfig,
      setCompanyConfig
    } = useCompanyConfig({
      'via_email': window.vm.$t('company_notifications.via_email'),
      'via_whatsapp': window.vm.$t('company_notifications.via_whatsapp'),
    });

    return {
      isNetworkError,
      notificationsConfig,
      isSendingSetCompanyConfig,
      getCompanyConfig,
      setCompanyConfig,
    };
  },

  created() {
    const authUser = authUserService.get();
    this.getCompanyConfig(authUser.company_docline_api_id);
  },

  methods: {
    async loadAssistants() {
      const authUser = authUserService.get();
      const { data } = await this.$http.post(getReceptionistsUrl, { user_id: authUser.id, accion: 'index' }, { headers: getHeader() });

      return data.recepcionistas.map((receptionist) => ({
        id: receptionist.docline_api_id,
        companyId: receptionist.company_uuid,
        name: receptionist.company_name,
        surName: receptionist.surname,
        email: receptionist.email,
        phone: receptionist.phone??null,
      }));
    },
    async save() {
      const authUser = authUserService.get();
      const userPhone = (authUser.user.phone_prefix && authUser.user.phone) ? `${authUser.user.phone_prefix} ${authUser.user.phone}` : null;

      const receptionists = await this.loadAssistants();

      await this.setCompanyConfig({
        id: authUser.company_docline_api_id,
        name: authUser.nombreEmpresa
      }, {
        id: authUser.user.docline_api_id,
        companyId: authUser.company_docline_api_id,
        name: authUser.user.name,
        surName: authUser.user.surname,
        email: authUser.user.email,
        ...(userPhone && {phone: userPhone}),
      }, receptionists);

      if (this.isNetworkError) {
        this.toastError(this.$t('company_notifications.failed_set_config'));
      } else {
        this.toastSuccess(this.$t('empresa.edit_empresa_success'));
      }
    }
  }
};
</script>
